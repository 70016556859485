import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (
  environmentId: string,
): Promise<{
  alertWebhooks: AlertWebhook[]
  moralisStreamDetails?: MoralisStreamDetails
}> => {
  const { data } = await apiWrapper(
    axios.get<{
      alertWebhooks: AlertWebhook[]
      moralisStreamDetails?: MoralisStreamDetails
    }>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/alerts/webhooks`,
      ),
      requestConfig,
    ),
  )
  return data
}

export const create = async (
  environmentId: string,
  data: { url: string; events?: AlertWebhookEvent[] },
): Promise<AlertWebhook> => {
  const { data: webhook } = await apiWrapper(
    axios.post<AlertWebhook>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/alerts/webhooks`,
      ),
      data,
      requestConfig,
    ),
  )
  return webhook
}

export const update = async (
  environmentId: string,
  webhookId: string,
  data: { url?: string; events?: AlertWebhookEvent[] },
): Promise<AlertWebhook> => {
  const { data: webhook } = await apiWrapper(
    axios.put<AlertWebhook>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/alerts/webhooks/${webhookId}`,
      ),
      data,
      requestConfig,
    ),
  )
  return webhook
}

export default {
  getAll,
  create,
  update,
}
